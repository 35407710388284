<template>
  <div class="login">
    <div class="background">
      <img class="eyecon-logo" src="@/assets/eyecon.svg" />
      <img class="tv-logo" src="@/assets/tv.svg" />
      <div class="copyright">
        © {{ getYear() }} Top Vista Digital Media Solutions Ltd, all rights
        reserved.
      </div>
    </div>
    <div class="content">
      <div class="account-input" v-if="accountExist != 1">
        <div class="topic">Sign In</div>
        <div class="label">Account Id or Email</div>
        <input
          ref="account"
          type="text"
          v-model="account"
          @keyup.enter="checkAccountExist"
        />
        <button @click="checkAccountExist" :disabled="account.length <= 0">
          Next
        </button>
        <div class="message">
          <div v-if="accountExist == 2">Account not exist</div>
        </div>
        <div class="create-account">
          <div>You don't have an account?</div>
          <a href="" class="sign-up">Sign up to create a new account</a>
        </div>
      </div>
      <div class="account-input" v-else-if="!showTenantsList">
        <div class="topic">
          <div class="back-button" @click="backButtonClick" title="Back">
            <IconArrowLeft />
          </div>
          Welcome {{ account }}
        </div>
        <div class="label">Password</div>
        <input
          ref="password"
          type="password"
          v-model="password"
          @keyup.enter="login"
        />
        <button @click="login" :disabled="password.length <= 0">Sign In</button>
        <div class="message">
          <div v-if="wrongPassword">Wrong password</div>
        </div>
        <!--<div class="forgot-password">
          <a href="">Forgot password</a>
        </div>-->
      </div>
      <div v-if="showTenantsList && cloudLogin != null">
        <div v-if="cloudLogin.tenantsId.length == 0">
          No sites under this account
        </div>
        <div class="site-select" v-else-if="cloudLogin.tenantsId.length > 1">
          <div class="topic">
            You have multiple sites registered, pick a site you want to sign in:
          </div>
          <div class="site">
            <div class="site-inner">
              <div
                v-for="t in cloudLogin.tenantsId"
                :key="t"
                class="item"
                @click="openUrl(t)"
              >
                <IconWeb class="icon" />
                <span class="name">{{ site(t) }}</span>
                <!--<a class="name" :href="hqUrl(t)">{{ t }}</a>-->
                <IconChevronRightCircle class="pointer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import IconWeb from "@/components/Icons/IconWeb";
import IconArrowLeft from "@/components/Icons/IconArrowLeft";
import IconChevronRightCircle from "@/components/Icons/IconChevronRightCircle";
export default {
  name: "Login",
  components: {
    IconWeb,
    IconArrowLeft,
    IconChevronRightCircle,
  },
  methods: {
    ...mapActions(["__checkAccountExist", "__login"]),
    async checkAccountExist() {
      if (await this.__checkAccountExist(this.account)) {
        this.accountExist = 1;
      } else {
        this.accountExist = 2;
      }

      // Testing
      //this.accountExist = 1;

      if (this.accountExist === 1) {
        this.$nextTick(() => {
          this.$refs.password.focus();
        });
      }
    },
    base64Encode(str) {
      var bytesArray = [];

      for (var i = 0; i < str.length; ++i) {
        bytesArray.push(str.charCodeAt(i));
        bytesArray.push(0);
      }
      var binary = "";
      var bytes = new Uint8Array(bytesArray);
      var len = bytes.byteLength;
      for (i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    async login() {
      var password = this.base64Encode(this.password);
      var payload = { Username: this.account, Password: password };
      if (await this.__login(payload)) {
        var t = this.cloudLogin && this.cloudLogin.tenantsId;
        if (t && t.length > 1) {
          this.showTenantsList = true;
          console.log("show tenants list");
        } else if (t && t.length == 1) {
          window.location.href = this.hqUrl(t[0]);
        }
      } else {
        this.wrongPassword = true;
      }
      if (this.wrongPassword) {
        this.$refs.password.focus();
        this.password = "";
      }
    },
    backButtonClick() {
      this.accountExist = 0;
      this.password = "";
      this.account = "";
    },
    hqUrl(id) {
      var c = this.cloudLogin;
      return `https://${id}.${c.hostName}/CloudLoginRedirect?key=${c.loginKey}`;
      //return `http://localhost:8082/CloudLoginRedirect?key=${c.loginKey}`;
    },
    site(id) {
      var c = this.cloudLogin;
      return `${id}.${c.hostName}`.toLowerCase();
    },
    openUrl(id) {
      var c = this.cloudLogin;
      window.location.href = `https://${id}.${c.hostName}/CloudLoginRedirect?key=${c.loginKey}`;
    },
    getYear() {
      const d = new Date();
      return d.getFullYear();
    },
  },
  computed: {
    ...mapGetters(["cloudLogin"]),
  },
  data() {
    return {
      account: "",
      password: "",
      accountExist: 0,
      wrongPassword: false,
      showTenantsList: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.account.focus();
    });
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  align-items: center;
  .background {
    position: absolute;
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
    .eyecon-logo {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 70px;
      margin: 0px 10px;
    }
    .tv-logo {
      position: absolute;
      right: 0px;
      bottom: 0px;
      height: 20px;
      margin: 20px;
    }
    .copyright {
      font-size: 12px;
      opacity: 0.5;
      position: absolute;
      left: 0px;
      bottom: 0px;
      margin: 10px;
    }
  }
  .content {
    min-width: 400px;
    height: 400px;
    flex-flow: column;
    padding: 20px;
    font-size: 15px;

    .account-input {
      display: grid;
      justify-items: start;
      color: #999;
      .topic {
        margin-bottom: 20px;
        font-size: 22px;
        justify-self: self-start;
        align-items: center;
        color: #f29019;
        display: flex;
        column-gap: 5px;
        .back-button {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          display: grid;
          align-items: center;
          justify-items: center;
          cursor: pointer;
          svg {
            fill: #f29019;
            width: 20px;
            height: 20px;
          }
          &:hover {
            background-color: #f2901920;
          }
        }
      }
      .label {
        font-size: 14px;
      }
      .message {
        height: 60px;
        width: 100%;
        display: grid;
        justify-items: center;
        align-items: center;
        color: red;
      }
      .create-account {
        display: grid;
        margin-top: 5px;
        justify-self: center;
        .sign-up {
          margin-top: 20px;
          text-decoration: none;
        }
      }
      .forgot-password {
        margin-top: 5px;
        justify-self: center;
      }
      input {
        justify-self: stretch;
        font-size: 15px;
        min-height: 40px;
        padding: 0px 10px;
        margin-top: 10px;
        border: 1px solid #999;
        border-radius: 4px;
        background-color: #fefefe;
      }
      input:focus {
        outline: 1px solid #f2c01950;
        border: 1px solid #f2c019;
      }
      button:disabled {
        background-color: #ccc;
      }
      button {
        font-size: 17px;
        width: 100%;
        min-height: 42px;
        margin-top: 20px;
        padding: 0px 10px;
        border: 0px;
        border-radius: 4px;
        background-color: #f2c019;
        color: #ffffff;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 1px;
        word-spacing: 3px;
      }
      button:focus {
        outline: 2px solid #f2c01950;
      }
    }
    .site-select {
      display: grid;
      background-color: #00000005;
      padding: 20px;
      border-radius: 6px;
      .topic {
        justify-self: start;
        margin-bottom: 30px;
      }
      .site {
        position: relative;
        width: 500px;
        min-height: 300px;
        height: 300px;
        border: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        .site-inner {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          .item {
            min-height: 50px;
            background-color: #e0e0e0;
            border-radius: 4px;
            margin-bottom: 5px;
            display: grid;
            align-items: center;
            grid-template-columns: 40px 1fr 40px;
            column-gap: 5px;
            .icon {
              fill: #777;
              width: 26px;
              height: 26px;
              justify-self: center;
            }
            .name {
              justify-self: start;
              color: #777;
            }
            &:hover {
              background-color: #f2c019;
              cursor: pointer;
              .pointer {
                display: block;
              }
              .name {
                color: #fff;
              }
              .icon {
                fill: #fff;
              }
            }
            .pointer {
              fill: #fff;
              width: 26px;
              height: 26px;
              justify-self: center;
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
